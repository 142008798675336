<template>
  <div ref="keen" class="keen-slider" :class="sliderClasses">
    <slot />
  </div>
</template>

<script setup lang="ts">
import BezierEasing from 'bezier-easing'
import { useKeenSlider } from 'keen-slider/vue'

import type { KeenSliderPlugin, KeenSliderInstance } from 'keen-slider/vue'
import type { BaseSliderProps } from './types'

const props = defineProps<BaseSliderProps>()
const emit = defineEmits<{
  animationStart: [void]
  animationEnd: [void]
  dragStart: [void]
  dragEnd: [void]
  resize: [value: KeenSliderInstance]
  update: [value: KeenSliderInstance]
}>()

const ResizePlugin: KeenSliderPlugin = (slider) => {
  const observer = new ResizeObserver(function () {
    nextTick(slider.update)
    emit('resize', slider)
  })

  slider.on('created', () => {
    observer.observe(slider.container)
  })

  slider.on('destroyed', () => {
    observer.unobserve(slider.container)
  })

  emit('resize', slider)
}

const state = reactive({
  isDragging: false,
  isDisabled: false,
  spacing: 0,
})

const mergedOptions = computed(() => {
  return Object.assign(
    {
      mode: 'snap',
      selector: '.keen-slider > *',
      defaultAnimation: {
        duration: 650,
        easing: BezierEasing(0.4, 0, 0.2, 1),
      },
      detailsChanged(slider: KeenSliderInstance) {
        state.isDisabled = slider.options.disabled || false
        state.spacing =
          typeof slider.options.slides === 'object'
            ? typeof slider.options.slides?.spacing === 'number'
              ? slider.options.slides?.spacing
              : slider.options.slides?.spacing
                ? slider.options.slides?.spacing()
                : 0
            : 0

        emit('update', slider)
      },
      animationStarted() {
        emit('animationStart')
      },
      animationEnded() {
        emit('animationEnd')
      },
      dragStarted() {
        emit('dragStart')
        state.isDragging = true
      },
      dragEnded() {
        emit('dragEnd')
        state.isDragging = false
      },
    },
    props.options,
  )
})

const [keen, slider] = useKeenSlider(mergedOptions.value, [ResizePlugin])

const sliderClasses = computed(() => ({
  'cursor-grab':
    !state.isDisabled && !state.isDragging && slider.value?.options.drag,
  'cursor-grabbing': !state.isDisabled && state.isDragging,
  'flex justify-center space-x-[var(--spacing)]': state.isDisabled,
}))

defineExpose({ instance: slider })
</script>

<style scoped lang="postcss">
.keen-slider {
  @apply overflow-visible;

  --spacing: v-bind(`${state.spacing}px`);
}

.keen-slider :deep(.keen-slider__slide) {
  @apply overflow-visible;
}
</style>
